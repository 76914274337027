.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  flex: 1 0 auto;
  color: white;
}

.loginForm form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  flex: 1 0 50%;
  max-height: 300px;
  padding: 0 1rem;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3%;
}
.header {
  font-size: 1.5rem;
  padding-bottom: 10px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5%;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5%;
}

.inputWrapper label {
  font-weight: 500;
  width: 100%;
}

.inputWrapper input {
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  border: none;
  margin-top: 10px;
  border-radius: 4px;
}

.inputWrapper input:focus,
.inputWrapper input:active {
  outline: none;
}

.loginOptions {
  display: flex;
  justify-content: flex-end;
  padding-top:10px;
}

.loginOptions input {
  margin-right: 5px;
}

.loginOptions a {
  color: #fff;
  text-decoration: none;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  flex: 0 1 auto;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
}

.submitButton:visited {
  color: #fff;
}

.submitButton:hover {
  background-color: #8AC076;
}

.loadingWrapper {
  align-self: center;
}

@media screen and (min-width: 1025px) {
  .loginForm {
    justify-content: center;
    align-items: center;
  }

  .loginForm form {
    padding: 0;
  }
}
