.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.panel {
  margin-top: 10px;
}

.colLeft {
  composes: baseStyle from '../../shared/css/shared.module.css'; 
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
}

.colLeft li {
  margin: 0 0.5rem 0.5rem;
  margin-left: calc(45px + 0.5rem);
}

.dateDiv {
  color: rgb(112, 112, 112)
}

.activityItem > div {
  align-items: center;
  display: flex;
}

.completedList{
  list-style: none;
  padding: 1rem 0.5rem;  
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  max-height: 30vh;
}

@media screen and (min-width: 1400px) {
  .dashboard {
    display: grid;
    min-height: 500px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 2fr 2fr 1fr;
    grid-auto-rows: auto;
    grid-template-areas: 
      "tracker tracker notifications"
      "photos schedule notifications"
      "photos schedule photosAlt"
      "photos schedule photosAlt";
    width: 100%;
  }

  .panel {
    margin: 0;
  }

  .progressTracker {
    grid-area: tracker;
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .recentPhotos, .trackerVideo {
    grid-area: photos;
    grid-column: 1;
    grid-row: 2 / 5;
  }

  .schedule {
    grid-area: schedule;
    grid-column: 2;
    grid-row: 2 / 5;
  }

  .notifications {
    grid-area: notifications;
    grid-column: 3;
    grid-row: 1 / 3;
  }

  .recentPhotosAlt {
    grid-area: photosAlt;
    grid-column: 3;
    grid-row: 3 / 5;
    min-height: 300px;
  }

  .messages,
  .notifications {
    min-width: 300px;
  }

  .trackerDescription {
    padding-top: .625rem;
  }

  .colLeft {
    grid-area: schedule;
    grid-column: 2;
    grid-row: 2 / 5;
    composes: baseStyle from '../../shared/css/shared.module.css'; 
  }
  
  .colLeft li {
    margin: 0 0.5rem 0.5rem;
    margin-left: calc(45px + 0.5rem);
  }

  .dateDiv {
    color: rgb(112, 112, 112)
  }

  .activityItem > div {
    align-items: center;
    display: flex;
  }

  .titleBar {
    composes: titleBar from '../../shared/css/shared.module.css';
  }
}
