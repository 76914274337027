.container {
  height: 32px;
  width: 32px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}

.line {
  background-color: #1A3464;
  height: 2px;
  width: 20px;
  transition: all 0.2s ease;
}

.lineTop {
  color: #1A3464;
  margin-bottom: 5px;
}

.lineMiddle {
  opacity: 1;
}

.lineBottom {
  margin-top: 5px;
}

.lineTopOpen {
  transform: rotate(45deg);
  transform-origin: top left;
  margin-bottom: 5px;
}

.lineMiddleOpen {
  opacity: 0;
  transform: translateX(-16px);
}

.lineBottomOpen {
  transform: translateX(-1px) rotate(-45deg);
  transform-origin:top left;
  margin-top: 5px;
}

@media screen and (min-width: 1025px){
  .container {
    display: none;
  }
}
