.CustomPage {
  width: 100%;
  height: 100%;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.CustomPage iframe {
  border: none;
  height: 100%;
  width: 100%;
}
