.node {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 120px;
  margin-left: 3px;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  background-color: white;
}

.node:focus{
  outline-width: 0px;
  background-color: white;
}

.wrapper {
  width: 100%;
}

.nodeBody {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  text-align: center;
  cursor: pointer;
}

.nodeBody:hover {
  border: 2px solid #ffffff;
}

.nodeSelected {
  border: 2px solid #B0B0B0;
}

.stepNumber {
  margin-top: 0.5rem;
  text-align: center;
}

.current .stepNumber {
  font-weight: 600;
}

.inProgress .stepNumber {
  font-weight: 600;
}
