.Alert {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

.modal {
  background: #fff;
  border-radius: 6px;
  left: 50%;
  max-width: 600px;
  padding: 0 0 2rem 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.Alert h1 {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  text-align: left;
}

.Alert h2 {
  color: #292929;
  font-size: 1.125rem;
  margin: 0 0 1.25rem 0;
}

.message {
  color: #292929;
  font-size: 1rem;
  text-align: center;
  margin-top: 2.0rem;
  padding: 0 2rem;
  white-space: pre-wrap;
}

.Alert ul {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.Alert a {
  margin-right: 0.75rem;
}
.Alert a:last-child {
  margin-right: 0;
}

.Alert img {
  width: 50px;
}

.buttonWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  width: 280px;
}

.Alert button {
  border-radius: 5px;
  color: #fff;
  font-size: 0.875rem;
  margin-top: 2rem;
  padding: 0.5rem 2.5rem;
}

.Alert button:nth-child(2) {
  margin-left: 0.5rem;
}
