.photoOverlay {
  height: 90%;
  width: 90%;
  background-color:white;
  position: fixed;
  border-radius:5px;
  border-color: black;
  border-width: 1.5px;
  border-style:solid;
  top: 50%;
  left: 50%;
  max-width: 800px;
  max-height: 90vmin;
  transform: translate(-50%, -50%);
}

.titleBar {
  composes: titleBar from '../../../shared/css/shared.module.css';
  position:relative;
}

.modulePhoto {
  height: auto;
  max-height: 90%;
  max-width: 90%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 5px;
}

.moduleContainer {
  height: 85%;
  width: 100%;
}

.modulePhotoContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.closeIcon {
  top: 50%;
  right: 5px;
  position: absolute;
  transform: translateY(-50%);
  border-width: 0;
  background-color:#273754;
}

.deleteButton {
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  margin: 0 auto;
  padding: 1rem 1.5rem;
}

.deleteButton span {
  margin-right: 0.5rem;
}

.chevronRight {
  border-radius: 5px;
  top: 50%;
  right: 5px;
  position: absolute;
  transform: translateY(-50%);
  border-width: 0;
  background-color:white;
}

.chevronLeft {
  border-radius: 5px;
  top: 50%;
  left: 5px;
  position: absolute;
  transform: translateY(-50%);
  border-width:0;
  background-color:white;
}

.stagePhotos {
  height:100px;
  width:100px;
  background-color:white;
}