.Messages {
  width: 100%;
}

.message {
  border-bottom: 1px solid #fff;
  display: flex;
  color: #fff;
  font-size: 1.125rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  position: relative;
  text-transform: uppercase;
  z-index: 3;
}
.message:last-child {
  border-bottom: none;
}

.message span {
  font-weight: 600;
  text-transform: capitalize;
}

.message button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  position: absolute;
  right: 1rem;
}

.closed {
  display: none;
}
