.Documents {
  display: flex;
  flex-direction: column;  
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
 }

 .Documents > div {
   margin-bottom: 1rem;
 }

@media screen and (min-width: 1025px) {
  .Documents {   
   display: flex;
   column-gap: 1.5rem;
  }

  .Documents > div {
    margin-bottom: 0;
  }
}
