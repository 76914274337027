.selections {
  height: 100%;  
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.col {
  composes: baseStyle from '../../shared/css/shared.module.css';
  height: 100%;
}

.col:first-child {
  margin-bottom: 1rem;
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
}

.selectionItems {
  list-style: none;
  overflow-y: scroll;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.selectionItems a {
  display: block;
  text-align: center;
  width: 100%;
}
.selectionListItems {
  overflow-y: scroll;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selections li {
  margin-bottom: 1rem;
}

.selections span:first-child {
  margin-right: 0.5rem;
}

.boldSpan{
  font-weight: bold;
}

.selections iframe {
  border: none;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1450px) {
  .selections {
    display: flex;
  }

  .col:first-child {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  
  .selectionItems {
    list-style: none;
    overflow-y: scroll;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex:1 1 200px;
  }
}
