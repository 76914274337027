.ForgotPassword {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.heroImage {  
  background-image: url('../../img/login-hero-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-basis: 40%;
  max-height: 340px;
  width: 100%;
}

.formBox {
  color: #fff;
  display: flex;
  flex: 1;
  justify-content: center;
}

.formBox form {
  max-width: 700px;
  padding: 0 1rem 2rem 1rem;
}

.formBox h1 {
  text-align: center;
  padding: 2rem 0;
}

.formBox p {
  margin-bottom: 1rem;
}

.formBox label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.formBox input {
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}

.formBox button {
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.5rem 1rem;
  width: 100%;
}

.loadingCover {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 25%;
}