.SelectionListItem {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 150px;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.SelectionListItem span {
  display: inline-block;
}

.SelectionListItem span:first-child {
  margin-right: 0.5rem;
}

.SelectionListItemImage {
  display: flex;
  justify-content: flex-start;
  margin:5px;
  width:100%;
  padding-left:20%;
}
.SelectionListItem img {
  border: 1px solid #A9A9A9;
  padding-top: 5px;
  max-height:100px;
  max-width:100px;
}
