.claimModal {
  background-color: #fff;
  padding: 1rem 2rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

.claimModal form {
  background-color: #f7f7f5;
  padding: 2rem;
}

.claimModal h1 {
  margin-bottom: 2rem;
  text-align: center;
}

.claimModal iframe {
  border: none;
  height: 100%;
  width: 100%;
}

.divider {
  background-color: #dbdbd5;
  height: 1px;
  margin: 2rem 0;
  width: 100%;
}

.formControl {
  border: 1px solid #ced4da;
  border-radius: 0;
  display: block;
  margin-left: calc(3rem + 25%);
  padding: 12px 16px;
  width: calc(75% - 3rem);
}

.exitButton {
  cursor: pointer;
  background: none;
  border: none;
  float: right;
  font-size: 75px;
  font-weight: 300;
  position: relative;
  text-decoration: none;
}

.exitCross {
  display: inline-block;
  transform: rotate(45deg);
}

@media only screen and (max-device-width: 600px) {
  .exitButton {
    cursor: pointer;
    background: none;
    border: none;
    float: right;
    font-size: 35px;
    font-weight: 300;
    position: absolute;
    text-decoration: none;
    right: 10px;
    top: 10px;
  }

  .claimModal {
    background-color: #fff;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .claimModal form {
    background-color: #f7f7f5;
    padding: 2rem;
    width: 100%;
  }

  .formControl {
    border: 1px solid #ced4da;
    border-radius: 0;
    display: block;
    margin-left: 0px;
    padding: 12px 16px;
    width: 100%;
  }
}