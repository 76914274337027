.contactUs {
  width: 100%;
  height: 100%;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.contactUs iframe {
  border: none;
  height: 100%;
  width: 100%;
}

.contactUs > div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
