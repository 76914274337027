.myProfile {
  width: 100%;
  height: 100%;
}

.registeredUser {
  margin-top:4vh;
  margin-left:2vw;
  margin-bottom:2vh;
}

.formContainer {
  height:80vh;
  display:flex;
  flex-direction:column;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.formDiv {
  height:80%;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-content:flex-start;
}

.formElementOne {
  width:45%;
  height:20%;
  display:flex;
  flex-direction:column;
  margin-right:2.5%;
}

.formLabelOneDiv {
  width:100%;
  display:flex;
}

.formLabelOne {
  width:50%;
}
.formInputOne{
  width:100%;
  height:25%;
  font-size:90%;
}
.formElementTwo{
  width:45%;
  height:20%;
  display:flex;
  flex-direction:column;
  margin-left:2.5%
}
.formLabelTwoDiv{
  width:100%;
  display:flex;
}
.formLabelTwo{
  width:50%;
}
.formInputTwo {
  width:100%;
  height:25%;
  font-size:90%;
}
.formElementThree {
  width:45%;
  height:20%;
  display:flex;
  flex-direction:column;
  margin-right:2.5%;
}
.formLabelThreeDiv {
  width:100%;
  display:flex;
}
.formLabelThree {
  width:50%;
}
.formInputThree {
  width:100%;
  height:25%;
  font-size:90%;
}
.formInputFour {
  width:100%;
  height:25%;
  font-size:90%;
}
.infoTextContainer {
  width:100%;
  height:auto;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}
.infoText {
  width:100%;
}
.myProfile input[type=text] {
  border: 1px solid lightblue;
  border-radius: 4px;
  
}
.myProfile input[type=email] {
  border: 1px solid lightblue;
  border-radius: 4px;
}
.myProfile input[type=tel] {
  border: 1px solid lightblue;
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .formElementOne{
    width:100%;
    margin:0px;
  }
  .formElementTwo{
    width:100%;
    margin:0px;

  }
  .formElementThree{
    width:100%;
    margin:0px;

  }
  .formElementFour{
    width:100%;
    margin:0px;
  }
}

