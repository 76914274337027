.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.customerInfoContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.logoContainer {
  flex: 1 0 auto;
  height: 70%;
}

.logo {
  height: 100%;
}

.userName {
  display: none;
}

.header a {
  text-decoration: none;
}

@media screen and (min-width: 1025px) {
  .header {
    padding: 0 4rem;
  }
  
  .logoContainer {
    flex-grow: 0;
    max-height: 74px;
  }
  
  .userName {
    display: unset;
    padding-right: 1rem;
  }

  .userSettings {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
