.claimTracker {
  composes: baseStyle from '../../../shared/css/shared.module.css';
  max-height: 345px;
  overflow: hidden;
  height: auto;
}

.claimTrackerHeader {
  composes: titleBar from '../../../shared/css/shared.module.css';
}

.dataLabels {
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
}

.submitClaimButton {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  color: white;
  font-size: 1.1rem;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  text-decoration: none;
}

.claimTicketWrapper {
  height: auto;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 18px;
 }

.claimNum {
  text-align: center;
  flex: 0 0 33%;
}

.name {
  display: none;
}

.description {
  display: none;
}

.date {
  text-align: center;
  flex: 0 0 33%;
}

.status {
  text-align: center;
  flex: 0 0 33%;
}

.legendWrapper {
  display: flex;
  margin-top: 10px;
}

.legendWrapper p {
  text-align: center;
  padding-left: 10px;
  height: 100%;
  font-size: 15px;
  margin: auto;
}

.colorKey {
  composes: baseStyle from '../../../shared/css/shared.module.css';
  margin-top: 1rem;  
  padding: 2%;  
  flex: auto;
  font-size: 16px;
  display: block;
}

.keyHeader {
  margin: 0 3.3rem 0 0;
}

.keyItem {
  display:flex;    
  margin-top: .7rem;
  align-items:flex-start;  
}

.keyLeft {
  width: 8rem;
  display: flex;
  align-items: center;
}

.keyRight {
  width: 81%;  
  margin: 2px 0 1rem 0;
}

.featurePlaceholder {
  margin: 1.5rem 0;
  text-align: center;
}

.colorBox {
  border-radius: 5px;
  box-shadow: 2px 2px #979797;
  display: inline-block;
  height: 25px;
  margin-right: 5px;
  width: 30px;
}

@media screen and (min-width: 700px) {
  .claimNum {
    flex: 0 0 15%;
  }
  .name {
    display: unset;
    flex: 0 0 30%;
  }

  .description {
    display: unset;
    flex: 0 0 35%;
  }

  .date {
    flex: 0 0 10%;
  }

  .status {
    flex: 0 0 10%;
  }
}

@media screen and (min-width: 1400px) {
  .submitClaimButton {
    width: 200px;
    max-width: 200px;    
  }
}
