.Document {
  align-items: center;
  color: #616161;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  word-break: break-word;
}

.icon {
  width: 60px;
}
