.item {   
    display: flex;
    flex-direction: row;
    margin: .5rem 0 .5rem .5rem;
}

.item .details {
    display: flex;
    flex-direction: column;
}

.details .date {
    color: rgb(112, 112, 112);
}

.details a {
    color: rgb(18, 101, 180);
    font-size: 12px;
    text-decoration: none;   
}

.docLink {
  display: block;
}

.message a {
    font-size: 1rem;
}

.message span {
    font-size: 1rem;
}

@media screen and (min-width: 1400px) {
    .item {   
        display: flex;
        flex-direction: row;
        margin: .5rem 0 .5rem .5rem;    
    }

    .item .icon {
        display: flex;
    }

    .item img{  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 3em;
        height: auto; 
    }

    .item .details {
        display: flex;
        flex-direction: column;       
    }

    .details .date {
        color: rgb(112, 112, 112);
    }

    .details .message {
        font-size: .8em;
    }

    .details .append {
        font-size: .8em;
        text-decoration: none;
    }

    .details a {
        color: rgb(18, 101, 180);    
    }

}

