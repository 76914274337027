.ServiceRequestItem {
  border-bottom: 1px solid #dbdbd5;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.ServiceRequestItem > button {
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
}

.fileUpload {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 1rem 1.5rem;
  position: relative;
}

.fileUpload input {
  opacity: 0;
  position: absolute;
}

.filePreview {
  display: flex;
  flex-basis: 75%;
  flex-wrap: wrap;
}

.filePreview > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
}

.filePreview > div img {
  margin-bottom: 0.5rem;
  width: 200px;
}
