.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.appHeader {
  background-color: #fff;
  flex: 0 0 50px;
  height: 50px;
  box-shadow: 0 5px 5px 0 rgba(160, 160, 160, .5);
}

.displayContainer {
  flex: 1 0 100%;
  max-height: calc(100% - 50px);
  width: 100%;
  position: relative;
}

.loadingOverlay {
  align-items: center;
  background: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 2;
}

.sideNavContainer {
  width: 90%;
  max-width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1; 
}

.selectActive {
  overflow: visible;
  z-index: 2 !important; 
}

@media screen and (min-width: 1025px) {
  .appHeader {
    flex: 0 0 130px;
    height: 130px;
  }

  .displayContainer {
    display: flex;
    justify-content: space-between;
    max-height: calc(100% - 130px);
    position: static;
  }

  main {
    position: relative;
    overflow-x: overlay;
  }

  .selectActive {
    overflow: visible !important;
    z-index: 2 !important; 
  }
  
  .sideNavContainer {
    flex: 0 0 400px;
    position: static;
  }
}
