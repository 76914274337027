.scheduleWidget {
  composes: baseStyle from '../../shared/css/shared.module.css';
  height: 100%;
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
}

.calendarPad {
  padding: 1%;
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  color: #000000;
}

.disclaimer span {
  font-weight: bold;
}

.clickedActive {
  background-color:#EFEFEF;
  height:100%;
  width:100%;
  position: absolute;
  top:0;
  left:0;
  color:#979797;
}

.centeredText {
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.beforeCurrentWeek {
  background-color: #599F41;  
}

.afterCurrentWeek {
  background-color: #D9D9D9;  
}

.currentWeek {
  background-color: #f7f077;  
}

.scheduleWidget a {
  text-decoration: none;
}

.calendarLink {
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  padding: 0.5rem 0;
  text-align: center;
}

.activityList { 
  list-style: none;
  padding: 1rem 0.5rem;  
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;   
}

.completedList {
  list-style: none;
  padding: 1rem 0.5rem;  
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;   
}

.shortList {
  list-style: none;
  padding: 1rem 0.5rem;  
}

.shortList h2 {
  color: #1265B4;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.shortList ul {
  list-style: none; 
  height: 130px;
  overflow: scroll;
}

.shortList li {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.colLeft {
  composes: baseStyle from '../../shared/css/shared.module.css'; 
  height: 638px;
}

.colLeft li {
  margin-bottom: 0.75rem;
}

.colRight {
  composes: baseStyle from '../../shared/css/shared.module.css'; 
  height: 638px;
}

.calendarFooter {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 0 2%;
}

.scheduleWidget .calendarFooter {
  height: 25px;
}


.taskList {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* hide scroll */ 
}

.taskList::-webkit-scrollbar,
.completedList::-webkit-scrollbar,
.activityList::-webkit-scrollbar {
  display: none; /* hide scroll */
}

.tileClick{
  height:50px;
  width:50px;
  background-color:red;
  position:relative;
}

.react-calendar__tile{
  overflow:unset;
}

.triangleOutter{
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-top-width: calc(1rem + 1px);
  border-right-width: calc(.8rem + 1px);
  border-right-style: solid;
  border-bottom-width: 0px;
  border-left-width: calc(.8rem + 1px);
  border-top-color: #A9A9A9;
  border-right-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
}

.triangleInner{
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-top-width: 1rem;
  border-right-width: .8rem;
  border-right-style: solid;
  border-bottom-width: 0px;
  border-left-width: .8rem;
  border-top-color: white;
  border-right-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-49%, -112%);
}

.clickedElementStyle{
  height: 9rem;
  width: 12rem;
  position: absolute;
  z-index: 100000;
  background-color: white;
  top: -10rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  box-shadow: 0px 0px 7px black;
  z-index: initial;
  text-align: left;
}

.secondaryWrapper{
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  overflow: hidden;
}

.chatbubbleInner{
  height: 100%;
  width: calc(100% + 18px);
  position: absolute;
  top: 0;
  overflow-y: scroll

}

.popupDate{
  font: Arial;
  text-transform: uppercase;
  font-weight: 900;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #273754;
}

.tentativeNote{
  font: Arial;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 5px;
  color: #273754;
}

.clickedElementStyle li {
  list-style: none;
  margin-left: 15px;
  margin-bottom: 15px;
  color: black;
  font: Arial;
}

.closeButton{
  height: auto;
  width: auto;
  color:black;
  position:absolute;
  right:0;
  z-index:100;
}

ul{
  list-style-type: none;
}

@media screen and (min-width: 1400px) {
  .shortList {  
    height: 166px; 
  }
  
  .calendarPad {     
    display: flex;
    flex-direction: column;    
    flex: auto;
  }  
/* } */

/* @media screen and (min-width: 1415px) { */
  .schedule {
    display: flex;
    justify-content: space-between;    
    composes: paddingAfterLogin from '../../shared/css/shared.module.css';     
  }

  .colLeft {
    width: 22.5%;    
  }

  .colRight {
    width: 75%;   
  }

  .box {
    width: 2.5em;
    height: 1.8em;
  }
}

@media screen and (min-height: 915px) {  
  .shortList, .shortList ul {  
   height: auto;
  } 
}
