.photo {
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  margin-right: 1%;
  max-width: 320px;
  height: 92%;
}
  
.photo img {
  border-radius: 10px;
  width: auto;
  height: 90%;
}
  
.details {
  font-size: 0.75rem;
  text-align: center;
}
