.category {

}

.categoryName {
  display: flex;
  align-items: center;
  color: white;
  padding-left: 10px;
  background: rgba(39, 55, 84, 0.8);
  font-size: 1.125rem;
  height: 40px;
  margin-bottom: 5px;
  text-transform: capitalize;
  border-radius: 5px 5px 0 0;
  flex-shrink: 0;
}

.location {
  margin-left:20px;
}
.locationName {
  display: flex;
  align-items: center;
  color: white;
  padding-left: 10px;
  background: rgba(39, 55, 84, 0.5);
  font-size: 1.125rem;
  height: 40px;
  margin-bottom: 5px;
  text-transform: capitalize;
  border-radius: 5px 5px 0 0;
  flex-shrink: 0;
}