.baseStyle {
  display: flex;
  flex-direction: column;
  width: 100%; 
  border-radius: 5px;  
  margin-top: 10px;
  border: 1px solid #e7e7e7;
}

.titleBar {
  display: flex;
  align-items: center;
  color: white;
  padding-left: 10px;
  background: #273754;
  font-size: 1.125rem;
  height: 40px;
  margin-bottom: 5px;
  text-transform: capitalize;
  border-radius: 5px 5px 0 0;
  flex-shrink: 0;
}

.panelContent {
  display: flex;
  flex-direction: column;
}

.paddingAfterLogin {
  padding: 25px;
}

.sharedDocContainer{
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-gap: 5%;
  margin: 5%;   
}

.iconLinkText{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .8rem;
  color: rgb(97, 97, 97);
  text-decoration: none;   
}

.iconDocImage{
  width:4em;   
  margin-bottom: .5em;
}
@media screen and (min-width: 1400px) {
  .baseStyle {
    margin: 0;
  }
}
