.DocContainer {
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
}

.heading {
  composes: titleBar from '../css/shared.module.css'
}

.statusText {
  margin: 1.5rem 0;
  text-align: center;
}

.docList {
  align-content: flex-start;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem;
}
.docList::-webkit-scrollbar {
  width: 0px;
}

.docList a {
  margin-bottom: 1rem;
  margin-left: 5%;
  width: 30%;
}
.docList a:nth-child(3n + 4) {
  margin-left: 0;
}
.docList a:nth-child(1) {
  margin-left: 0;
}

@media screen and (min-width: 1025px) {
  .DocContainer {
    flex-shrink: 1;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1581px) {
  .docList a {
    width: 45%;
  }
  .docList a:nth-child(odd) {
    margin-left: 0!important;
  }
  .docList a:nth-child(3n + 4) {
    margin-left: 5%;
  }
}
