.progressBar {
  display: flex;
  justify-content: center;
}

.barStart {
  /* background-color: #1265B4; */
  height: 60px;
  width: 20px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}

.barStart:hover {
  border: 2px solid #ffffff;
}


.barEnd {
  /* background-color: #616161; */
  border-radius: 0 10px 10px 0;
  height: 60px;
  width: 20px;
  margin-left: 3px;
  cursor: pointer;
}

.barEnd:hover {
  border: 2px solid #ffffff;
}


.completedBarEnd {
  composes: barEnd;
  /* background-color: #1265B4; */
}

.keyContainer {
  font-weight: 400;
  display: flex;
  flex: 0 1 85%;
  margin-top:10px;
}

.keyItem {
  display: flex;
  align-items: center;
  max-width: 90px;
  margin-right: 1%;
  white-space: nowrap;
}

.box {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 5px;
}

/* Alternate styles below for arrows in place of XD design - 
  might be more intuitive for users that they can click to move steps */

/* .barStart {
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid #1265B4;
}

.barEnd {
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #616161;
  margin-left: 3px;
}

.completedBarEnd {
  composes: barEnd;
  border-left: 30px solid #1265B4;
} */