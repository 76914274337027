.login {
  display: flex;
  flex-direction: column;
  align-items: normal;
  height: 100%;
  position: relative;
  background-color: #273754;
}
.loginContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.heroImage {
  background-image: url('../../img/login-hero-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(4px);
  opacity: 0.6;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.formContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding-top: 2%;
  background-color: #fff;
}

.registerLink {
  display: flex;
  align-items: center;
  flex: 0 1 60px;
  justify-content: center;
  color: white;
  background-color: #273754;
}

.registerLink a {
  color: #1265B4;
  text-decoration: none;
  margin: 0 5px;
}

.registerLink span {
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  .homePage {
    position: static;
  }

  .heroImage {
    width: 50%;
    position: unset;
    opacity: unset;
    filter: unset;
  }

  .formContainer {
    position: unset;
    width: 50%;
    background-color: #273754;
    align-items: center;
    padding-top: 2%;
  }

  .registerLink {
    align-self: flex-start;
  }

  .version {
    color: #FFF;
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
    font-size: 0.75rem;
    z-index: 10;
  }
}
