.SelectionItem {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  max-width: 250px;
  min-width: 200px;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 1rem;
  width: calc(50% - 0.25rem);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 150px;
}

.SelectionItem img {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  width: 100%;
}

.SelectionItem span {
  display: inline-block;
}

.SelectionItem span:first-child {
  margin-right: 0.5rem;
}
