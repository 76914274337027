.ResetPassword {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.heroImage {  
  background-image: url('../../img/login-hero-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-basis: 200px;
  width: 100%;
  z-index: 1; 
}

.formBox {
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.formWrapper {
  max-width: 785px;
}

.formBox h1 {
  padding: 2rem 0;
  text-align: center;
}

.formBox label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.formBox label div {
  display: flex;
  justify-content: space-between;
}

.validationError {
  color: red;
}

.formBox input {
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}

.formBox button {
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.5s linear;
  width: 100%;
}
