.sideNavContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  flex-direction: column;
}

.sideNav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
  /* padding: 1rem 1rem; */
  color: #fff;
  overflow-y: auto;
}

.sideNav.hidden {
  width: 0%;
  padding: 0;
  transition: 2s;
}

.primaryMenu,
.default {
  background-color: #182845;
  text-transform: uppercase;
  font-weight: 600;
}

.settingsMenu {
  background-color: #757575;
  text-transform: capitalize;
  font-weight: 500;
}

.navMenuImages {
  height: calc(140px - 1rem);
  width: 100%;
  display: flex;
  padding: 0 1rem 1rem 0;
  justify-content: space-between;
}

.navMenuImages > div {
  height: calc(140px - 2rem);
  width: calc(140px - 2rem);
}
