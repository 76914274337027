.container {
  height: 100%;
  background-color: white;
}

.SelectHome {
  display: flex;
  flex-direction: column;
  width: 100%;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.loadingIndicator {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.preContractSplash {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
}

.panelContent {
  background: #f7f7f5;
  composes: panelContent from '../../shared/css/shared.module.css';
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  padding: 2rem 1.5rem;
}

@media screen and (min-width: 1025px) {
  .loadingIndicator {
    left: -350px;
    width: calc(100% + 350px);
  }
}
