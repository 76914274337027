.photos {
  width: 100%;
  height: 100%;
  position: relative;
  transform: rotate(0deg);
  /* ^ hack for binding fixed children relative to this element vs viewport */
  overflow: hidden;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}
.photoSectionWrapperScrollHider {
  height:100%;
  width: 100%;
  overflow: hidden;
}

.photoSectionWrapper {
  width: calc(100% + 18px);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.recentPhotos {
  composes: baseStyle from '../../shared/css/shared.module.css';
  height: 100%;
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
  position:relative;
}

.panelContent {
  composes: panelContent from '../../shared/css/shared.module.css';
  height: 100%;
  padding: 1%;
  min-height: 300px;
}

.photoPanel {
  flex: 1;
}

.largePhoto {
  width: 100%;
  height: 60%;
  margin-bottom: 2%;
  border-radius:10px;
}

.smallPhoto {
  display: inline-block;
  width: 49%;
  height: 38%;
  border-radius: 10px;
}

.pullRight {
  composes: smallPhoto;
  margin-left: 2%;
}

.photoLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  align-self: center;
  margin-top: 1%
}

.photoLink a {
  text-transform: capitalize;
  text-decoration: none;
  color: #1265B4;
}

.fileSubmitterOverlay {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 530px) {
  .panelContent {
    min-height: 500px;
  }
}

@media screen and (min-width: 1000px) {
  .panelContent {
    min-height: 700px;
  }
}

@media screen and (min-width: 1400px) {
  .panelContent {
    min-height: auto;
  }
}
