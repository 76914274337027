.photoSection {
  margin-bottom: 2rem;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

.titleBar {
  composes: titleBar from '../../css/shared.module.css';
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  width: calc(100% - 18px);
}

.titleBar button {
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
}

.titleBar button span {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.titleBar button svg {
  font-size: 1rem;
}

.photoSection h1 {
  background: #273754;
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius:   5px 5px 0px 0px;
}

 /* 18px is width of scroll bar*/
.photoContainer.active {
  display: flex;
  flex-wrap: wrap;
  height: 70vh;
  max-height: 280px;
  overflow-y: scroll;
  padding: 1%;
  width: calc(100% + 18px);
}

.noPhotos {
  margin-left: 0.5rem;
}

@media screen and (min-width: 48rem) {
  .titleBar button span {
    font-size: 1rem;
  }
  
  .titleBar button svg {
    font-size: 1.25rem;
  }
}
