.calendarKey {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  width: 100%;
}

.widgetCalendarKey {
  composes: calendarKey;
  font-size: 0.5rem;
}

.keyLabel {
  flex: 0 0 15%;
  text-transform: uppercase;
}

.keyContainer {
  display: flex;
  flex: 0 1 85%;
  flex-wrap: wrap;
  justify-content: space-around;
}

.keyItem {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  flex: 0 1 31%;
  margin-right: 1%;
  white-space: nowrap;
}

.box {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 5px;
}
