.activityItemExpanded {
    align-items: center;
    display: flex;
    margin: .5em;
}

.activityItemExpanded img {
  margin-right: 0.5rem;
  width: 25px;
}
