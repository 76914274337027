.faqs {
  width: 100%;
  height: 100%;
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.faqs iframe {
  border: none;
  height: 100%;
  width: 100%;
}

.faqs > div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
