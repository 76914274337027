.progressTracker {
  composes: baseStyle from '../../shared/css/shared.module.css';
  margin-top: 0px;
}

.fullscreen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image {
  flex-grow: 1;
  width: 100%;
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
}

.panelContent {
  composes: panelContent from '../../shared/css/shared.module.css';
  text-transform: capitalize;
  font-size: .75rem;
  font-weight: 600;
  padding: 1%;
}

.noImage {
  flex-grow:1;
  width: 100%;
  composes: titleBar from '../../shared/css/shared.module.css';
  text-align: center;
  display: grid;
  border-radius: 0;
}

