.react-calendar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 99%;
  margin: 0 auto;  
  border:none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  background: #fff; /* #A4A4A4 */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}

.react-calendar__navigation__label {
  color: #034180; /* #fff */
  font-size: 0.875rem;
  pointer-events: none;
}

.full .react-calendar__navigation__label {
  font-size: 1.875rem;
}

.react-calendar__navigation__arrow {
  color: #034180; /* #fff */ 
}

.react-calendar__navigation__prev-button {
  border-top-left-radius: 10px;
}

.react-calendar__navigation__next-button {
  border-top-right-radius: 10px;
}

.full .react-calendar__tile {
  height: 10vw;
  max-height: 70px;
  min-height: 27px;
  overflow:unset;
}

.react-calendar__month-view__weekdays {
  color: #fff;
}

/* onLoad , auto hight-light current date */
.react-calendar__tile--now {
  color: #fff!important;
}

.react-calendar__tile {
  transform:rotate(0);
  overflow:unset !important;
  
}
.react-calendar__tile--hover {
  background-color:#034180;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus {
  background-color:#EFEFEF;
  color:#979797;
}
