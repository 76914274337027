.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  flex: 1 0 auto;
  color: white;
}

@media screen and (min-width: 1025px) {
  .wrapper {
    justify-content: center;
    align-items: center;
  }
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  flex: 0 1 auto;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
}
.submitButton:visited {
  color: #fff;
}
.submitButton:hover {
  background-color: #8AC076;
  opacity: 0.9;
}

.logoutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  flex: 0 1 auto;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #000;
  background-color: #A9A9A9;
}
.logoutButton:visited {
  color: #000;
}
.logoutButton:hover {
  opacity: 0.9;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header {
  font-size: 1.5rem;
  padding-bottom: 10px;
}
.authError {
  padding: 30px;
}