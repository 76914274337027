.ModalVideo {
  height: 100%;
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 100;
  background-color: rgba(46,49,49,0.5);
}

.videoContainer {
  height: 80%;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.video {
  composes: baseStyle from '../../shared/css/shared.module.css'; 
  height: 100%; 
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
  margin-bottom: 0px !important;
  display:flex;
  justify-content: space-between;
}

.close {
  border: 1px solid white;
  border-radius: 5px;
  font-size: 28px;
  height: 100%;
  width: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.panelContent {
  flex-grow: 1;  
  height: 30vh; 
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* hide scroll */ 
}

.panelContent iframe {
  border: none;
  height: 100%;
  flex-grow: 1;
  width: 100%;
}

.panelContent::-webkit-scrollbar {
  display: none; /* hide scroll */
}

@media screen and (max-width: 1399px) {
  .notifications .downArrow {
    padding:.5em ;
    width: 3em;
    margin: 0 auto;  
   }
}

