.TrackerVideo {
  composes: baseStyle from '../../shared/css/shared.module.css';
  height: 100%;
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
  position:relative;
}

.panelContent {
  composes: panelContent from '../../shared/css/shared.module.css';
  padding: 1%;
  position: relative;
  min-height: 300px;
}

.panelContent iframe {
  border: none;
  flex-grow: 1;
  width: 100%;
}

.panelContent span {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}