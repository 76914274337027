.ClaimTicket {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  min-height: 50px;
  border-radius: 5px;
  padding: 0.5rem 0;
  width: 100%;
}

.dataRow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.claimNum {
  composes: dataRow;
  flex: 0 0 33%;
}

.name {
  composes: dataRow;
  display: none;
}

.descriptionWrapper {
  height:100%;
  overflow:hidden;
  display:none;
  font-size: 1rem;
  flex: 0 0 35%;
  position:relative;
  text-align: left;
  margin-right: 5px;
}

.date {
  composes: dataRow;
  flex: 0 0 33%;
  min-width: 65px;
}

.status {
  composes: dataRow;
  flex: 0 0 33%;
}

@media screen and (min-width: 700px) {
  .dataRow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .claimNum {
    flex: 0 0 15%;
  }
  .name {
    display: unset;
    flex: 0 1 30%;
  }

  .description {
    display: unset;
    flex: 0 0 35%;
  }

  .descriptionWrapper {
    display: unset;
    flex: 0 0 35%;
  }

  .date {
    flex: 0 0 10%;
  }

  .status {
    flex: 0 0 10%;
  }
}
