.sideNavItems {
  flex: 0 0 60px;
  padding-left: 2rem;
  cursor: pointer;
}

.sideNavItems a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
}

.subItem {
  flex: 0 0 60px;
  padding-left: 4rem;
  cursor: pointer;
}

.subItem a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* color: #fff; */
  text-decoration: none;
  font-size: 0.9rem;
}

.defaultMessage {
  text-transform: capitalize;
  font-weight: 500;
}

.sideNavItems a:visited {
  /* color: #fff; */
}

.sideNavItems a::selection {
  /* color: red; */
}

/* .sideNavItems a::active {
  color: yellow;
} */

.primaryMenu:hover {
  background-color: #4D5D7A;
}

.settingsMenu:hover {
  background-color: #A5A5A5;
}

