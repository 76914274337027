.notifications {
  composes: baseStyle from '../../shared/css/shared.module.css'; 
  height: 100%; 
}

.titleBar {
  composes: titleBar from '../../shared/css/shared.module.css';
}

.panelContent {
  flex-grow: 1;  
  height: 30vh; 
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* hide scroll */ 
}

.panelContent::-webkit-scrollbar {
  display: none; /* hide scroll */
}
