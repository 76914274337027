.LoadingOverlay {
  align-items: center;
  background: rgba(39, 55, 84, 0.8);
  bottom: 0;
  display: flex;
  color: #FFF;
  font-size: 1.5rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}