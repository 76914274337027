.warranty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative; 
  composes: paddingAfterLogin from '../../shared/css/shared.module.css'; 
}

.claimTrackerContainer {
  width: 100%;
  height: fit-content;
  margin-bottom: 1rem;
}

.mfgDocContainer {
  width: 100%;
}

.builderDocContainer {
  margin-bottom: 1rem;
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .warranty {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 343px 1fr;
    grid-template-areas: 
      "claimTracker mfgWarranty"                   
      "claimTracker bldrWarranty"
  }

  .claimTrackerContainer {
    grid-area: claimTracker;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .mfgDocContainer {
    grid-area: mfgWarranty;
    grid-column: 2 / 3;
    grid-row: 1 / 2;   
  }
  .mfgDocContainer > div {
    height: 100%;
  }

  .builderDocContainer {
    grid-area: bldrWarranty;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: flex;
    margin-bottom: 0;
  }
  .builderDocContainer > div {
    height: 100%;
    width: 100%;
  }
}
