.FileSubmitter {
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 90%;
  left: 50%;
  max-height: 500px;
  max-width: 1300px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  z-index: 2;
}

.FileSubmitter header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.FileSubmitter header button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.FileSubmitter form {
  border-bottom: 1px solid #000;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
}

.FileSubmitter form > div:first-child {
  display: flex;
  margin-bottom: 0.5rem;
}

.FileSubmitter form button {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem 1.5rem;
}

.FileSubmitter form button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.fileUpload {
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
  padding: 1rem 1.5rem;
  position: relative;
}

.fileUpload input {
  cursor: pointer;
  font-size: 1rem;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.filePreview {
  margin: 1rem;
}

.filePreview img {
  width: 100%;
}

.filePreviewWrapper {
  flex-grow: 1;
  position: relative;
}

.FileSubmitter ul {
  height: 100%;
  left: 0;
  list-style: none;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
}

.loadingWrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.fileNumberWarning {
  background: red;
  color: #fff;
  padding: 0.5rem;
}

@media screen and (min-width: 48rem) {
  .FileSubmitter {
    width: 70%;
  }
}

@media screen and (min-width: 85.375rem) {


  .FileSubmitter ul {
    display: flex;
    flex-wrap: wrap;
  }

  .filePreview img {
    width: 380px;
  }
}
